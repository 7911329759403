.two-zero-four-eight{
  height: 100vh;
}
.two-zero-four-eight .nav {
  height: 72px;
  align-content: center;
}
.two-zero-four-eight .game {
  font-family: Helvetica, sans-serif;
  margin: auto;
  width: calc(77vmin);
  height: calc(100vh - 72px);
}
.two-zero-four-eight .game .header {
  width: calc(77vmin);
}
.two-zero-four-eight .game .header .score-card{
  padding: 0.5vmin;
  background-color: #000000 !important;
  width: 120px;
  border-radius: calc(2vmin);
}
.two-zero-four-eight .game-board {
  width: calc(76vmin);
  height: calc(76vmin);
  background-color: #000000 !important;
  border-radius: calc(2vmin) !important;
  padding: calc(0.5vmin);
}
.two-zero-four-eight .game-board .square {
  user-select: none;
  box-sizing: border-box;
  margin: 0.5vmin;
  width: calc(18vmin);
  height: calc(18vmin);
  line-height: calc(18vmin);
  background: #fff7;
  vertical-align: bottom;
  text-align: center;
  color: transparent;
  font-size: 0;
  border-radius: calc(18vmin / 15);
  font-family: sans-serif;
  font-weight: bold;
  opacity: 1;
  position: absolute;
  z-index: 1;
  transition: filter 0.5s;
}
.two-zero-four-eight .game-board .square.combined {
  text-shadow: 0 0 15px rgb(71, 71, 0);
}
.two-zero-four-eight .game-board .square.square-2 {
  z-index: 10;
  background: #a15b00;
  color: #FFFFFF;
  font-size: calc(18vmin / 2);
}
.two-zero-four-eight .game-board .square.square-4 {
  z-index: 10;
  background: #2c310e;
  color: #FFFFFF;
  font-size: calc(18vmin / 2);
}
.two-zero-four-eight .game-board .square.square-8 {
  z-index: 10;
  background: #1e6431;
  color: #FFFFFF;
  font-size: calc(18vmin / 2);
}
.two-zero-four-eight .game-board .square.square-16 {
  z-index: 10;
  background: #763cad;
  color: #FFFFFF;
  font-size: calc(18vmin / 2);
}
.two-zero-four-eight .game-board .square.square-32 {
  z-index: 10;
  background: #16666c;
  color: #FFFFFF;
  font-size: calc(18vmin / 2);
}
.two-zero-four-eight .game-board .square.square-64 {
  z-index: 10;
  background: #9e3728;
  color: #FFFFFF;
  font-size: calc(18vmin / 2);
}
.two-zero-four-eight .game-board .square.square-128 {
  z-index: 10;
  background: #77331a;
  color: #FFFFFF;
  font-size: calc(18vmin / 2);
}
.two-zero-four-eight .game-board .square.square-256 {
  z-index: 10;
  background: #704920;
  color: #FFFFFF;
  font-size: calc(18vmin / 2);
}
.two-zero-four-eight .game-board .square.square-512 {
  z-index: 10;
  background: #92992d;
  color: #FFFFFF;
  font-size: calc(18vmin / 2);
}
.two-zero-four-eight .game-board .square.square-1024 {
  z-index: 10;
  background: #288034;
  color: #FFFFFF;
  font-size: calc(18vmin / 3);
}
.two-zero-four-eight .game-board .square.square-2048 {
  z-index: 10;
  background: #39276b;
  color: #FFFFFF;
  font-size: calc(18vmin / 3);
}
.two-zero-four-eight .game-board .square.square-4096 {
  z-index: 10;
  background: #1f8654;
  color: #FFFFFF;
  font-size: calc(18vmin / 3);
}
.two-zero-four-eight .game-board .square.square-8192 {
  z-index: 10;
  background: #60125a;
  color: #FFFFFF;
  font-size: calc(18vmin / 3);
}
.two-zero-four-eight .game-board .square.square-16384 {
  z-index: 10;
  background: #4839ab;
  color: #FFFFFF;
  font-size: calc(18vmin / 4);
}
.two-zero-four-eight .game-board .square.square-32768 {
  z-index: 10;
  background: #299f48;
  color: #FFFFFF;
  font-size: calc(18vmin / 4);
}
.two-zero-four-eight .game-board .square.square-65536 {
  z-index: 10;
  background: #5f308b;
  color: #FFFFFF;
  font-size: calc(18vmin / 4);
}
.two-zero-four-eight .game-over {
  width: calc(76vmin);
  height: calc(76vmin);
  z-index: 9999;
  border-radius: calc(2vmin) !important;
  position: absolute;
}
